import { useRef, useState } from "react";
import { decryptData, defaulUrl, Loading } from "../components/const";
import Swal from "sweetalert2";

const ModalVersement = ({ idcompte }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const profile = decryptData('user_profile');
    const ref = useRef()
    const handleSubmite = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formdata = new FormData(e.target);
        formdata.append('iduser', +profile.iduser);
        let status = 0;
        fetch(`${defaulUrl}versements/add`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            console.log(data)
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {

                    ref.current.click();
                })
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }
    return (
        <div className="modal fade " id="modalverser" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Versement compte client</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={(e)=>handleSubmite(e)} >
                        <div className="modal-body position-relative">
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant versé</label>
                                <input type="number" className="form-control hide-arrow" name="montant" />
                                {errors?.montant && <span className="text-danger mt-2"> {errors?.montant}</span>
                                }
                            </div>
                            <div className="form-group " hidden>
                                <label htmlFor="" className="fs-5">Idcompte</label>
                                <input type="number" className="form-control hide-arrow" name="idcompte"  defaultValue={idcompte} />
                            </div>
                            {isLoading && <span className="position-absolute"> <Loading /></span>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                            <button type="submite" className="btn btn-success"  >Valider</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalVersement;