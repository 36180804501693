import { useRef, useState } from "react";
import Select from 'react-select';
import { decryptData, defaulUrl, Loading } from "../components/const";
import Swal from "sweetalert2";
import UseFetch from "../components/UseFetch";

const OperationsComptable = ({ titre, type }) => {
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    let [magasin, setMagasin] = useState(null);
    const { data: magasins, isloading } = UseFetch('magasins/liste');

    const ref = useRef();
    const handleSave = (e) => {
        e.preventDefault();
        let status = 0;
        if (!magasin) {
            setErrors({ 'idmagasin': 'Choisir le magasin' })
            return
        }
        setIsLoading(true)
        const formData = new FormData(e.target);
        formData.set('type', type)
        formData.set('idmagasin', magasin?.value)
        fetch(`${defaulUrl}comptable/add`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            console.log(data)
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {
                    ref.current.click();
                })
                setErrors(null)
            } else {
                setErrors(data.errors);
            }
        })
    }
    return (
        <div className="modal fade " id="modalcomptable" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">{titre}</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <span className="text-primary bg-secondary-subtle px-3"></span>
                    <form onSubmit={(e) => handleSave(e)}>
                        <div className="modal-body position-relative">
                            <div className="form-group">
                                <label htmlFor="">Magasin</label>
                                {isloading ? <Select /> :
                                    <Select defaultInputValue={magasin?.label} options={

                                        magasins.map((m) => (
                                            {
                                                value: m.id, label: m.mag
                                            }
                                        ))}
                                        onChange={setMagasin}

                                    />
                                }
                                {errors?.idmagasin &&
                                    <span className="text-danger py-2">{errors?.idmagasin}</span>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant</label>
                                <input type="number" className="form-control hide-arrow" name="montant" />

                                {errors?.montant &&
                                    <span className="text-danger py-2">{errors?.montant}</span>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Description</label>
                                <textarea name="description" id="description" className="form-control"></textarea>
                                {errors?.description &&
                                    <span className="text-danger py-2">{errors?.description}</span>
                                }
                            </div>
                            {isLoading && <span className="position-absolute"> <Loading /></span>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                            <button type="submite" className="btn btn-success">Enregistrer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default OperationsComptable;