
import { decryptData } from "../components/const";
import StockMagasin from "../Produits/StocksMagasins";
const ListeProduitParMagasin = () => {
    const id = decryptData('user_profile').idmagasin;
    return (
        <div className="produit">
            <StockMagasin idmag={id} />
        </div>
    );
}

export default ListeProduitParMagasin;