export const handleMenu = (e) => {
    document.querySelectorAll('.show').forEach(itme => {
        itme.classList.remove('show');
    })
    const parent = e.target.parentNode;

    const list = parent.querySelector('.dropdown-list');

    if (list) {
        list.classList.toggle('show');
    }

}