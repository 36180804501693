
import { decryptData, defaulUrl, Loading } from "../components/const";
import { useEffect, useState } from "react";

const VenteParConteneur = ({ id }) => {

    if (!id) {
        id = decryptData('user_profile').idmagasin;
    }
    const [datejour, setDateJour] = useState((new Date()).toISOString().slice(0, 10));
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const [isLoading, setIsLoading] = useState(false);
    const [ventePeriode, setVentesPeriode] = useState(null);

    const handleShowByPeriode = () => {
        setIsLoading(true);
        let status = 0;
        fetch(`${defaulUrl}ventes/conteneur/${id}/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsLoading(false);
            if (status === 200) {
                da?.sort((a, b) => a.conteneur.localeCompare(b.conteneur))
                setVentesPeriode(da);
            }
        })
    }

    console.log(ventePeriode)
    useEffect(() => {
        handleShowByPeriode();
    }, [])
    return (
        isLoading ? <Loading /> : <div className="container-fluid">

            <div className="row justify-content-between p-2 text-primary">
                <div className="col-lg-6 h3">
                    Liste des ventes ordonnee par conteneur
                </div>
                <div className="col-lg-5 d-flex gap-1 align-items-center">
                    <div className="form-group  col-4">
                        <input type="date" className="form-control" id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                    </div>
                    <div className="form-group col-4 ">
                        <input type="date" className="form-control" id="date2" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-primary col-2 align-self-end" onClick={() => handleShowByPeriode()}><i className="bx bx-search" ></i></button>
                </div>
            </div>

            {
                (ventePeriode?.length) ? <div className="table-responsive ">
                    <table className="table  table-hover table-striped shadow">
                        <thead className="table-dark">
                            <tr>
                                <th>CONTENEUR</th>
                                <th>PRODUIT</th>
                                <th>QTE</th>
                                <th>PVU</th>
                                <th>MONTANT</th>
                                <th>N°FACTURE</th>
                                <th>CLIENT</th>
                                <th>DATE</th>
                            </tr>
                        </thead>
                        <tbody className="plage">
                            {
                                ventePeriode?.map((prod, ind) => (
                                    <tr key={ind} className="tr">
                                        <td>{prod?.conteneur}</td>
                                        <td>{prod?.produit}</td>
                                        <td>{prod?.quantite}</td>
                                        <td>{prod?.pvu.toLocaleString()}</td>
                                        <td>{prod?.montant.toLocaleString()}</td>
                                        <td>{prod?.numero}</td>
                                        <td>{prod?.client}</td>
                                        <td>{new Date(prod?.created_at).toLocaleDateString()}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div> : <div className="row justify-content-center fs-3 bg-danger-subtle text-danger p-2">Aucune vente effectuee</div>
            }

        </div>


    );
}

export default VenteParConteneur;