import { Loading } from "../components/const";

const DetailFactures = ({ data: produits, isLoading }) => {
    return (
        <div className="modal fade" id="detail" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Détail de la facture </h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {
                            isLoading ? <Loading /> : produits ? <div className="table-responsive">
                                <table className="table-table-hover table bordered table striped">
                                    <thead className="table-primary">
                                        <tr>
                                            <th>N°</th>
                                            <th>PRODUIT</th>
                                            <th>QUANTITE</th>
                                            <th>PVU</th>
                                            <th>MONTANT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            produits.map((prod, ind) => (
                                                <tr key={ind}>
                                                    <td>{ind + 1}</td>
                                                    <td>{prod.produit}</td>
                                                    <td>{prod.quantite}</td>
                                                    <td>{prod.pvu.toLocaleString()}</td>
                                                    <td>{(prod.pvu * prod.quantite).toLocaleString()}</td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div> : <div className="w-100 text-center bg-danger-subtle text-danger fs-3">
                                ERREUR DE RECUPERATION DE DONNEES
                            </div>
                        }


                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailFactures;