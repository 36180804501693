import { useRef, useState } from "react";
import { decryptData, photoUrl, defaulUrl, Loading, encryptData } from "../components/const";
import Swal from "sweetalert2";
const Profile = () => {
    const user = decryptData('USER');
    const ref = useRef()
    const [photo, setPhoto] = useState(`${photoUrl}${user.photo}`);
    const [isloading, setIsLoading] = useState(false)
    const profile = decryptData('user_profile');
    const handleDisplay = (e) => {
        e.preventDefault();
        const uplodadeFile = ref.current.files[0];
        let cheminPhoto = null
        if (uplodadeFile) {
            cheminPhoto = URL.createObjectURL(uplodadeFile);
            setPhoto(cheminPhoto)
        }
    }

    const handleSubmite = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        let status = 0
        setIsLoading(true)
        fetch(`${defaulUrl}user/edite/${user.id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formData
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            console.log(data)
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                })
                encryptData('USER', data.user);
            }
        })
    }

    return (
        <div className="Profile">
            {isloading && <Loading />}
            <form onSubmit={(e) => handleSubmite(e)}>
                <div className="row p-2 bg-white rounded shadow">
                    <div className="col-lg-2 text-center border-end text-center d-flex justify-content-center mb-5">
                        <div className="photo position-relative p-2 ">
                            <img src={photo} className="photo bg-secondary-subtle border border-2" />
                            <a className="btn btn-secondary camera p-0" onClick={() => ref.current.click()}>
                                <i className="bx bx-camera mx-auto fs-3"></i>
                            </a>
                            <input hidden type="file" name="photo" id='photo' ref={ref} onChange={handleDisplay} />
                            <div className="span fw-bold mt-2">{user.email}</div>
                            <div className="span text-muted">({user.droit})</div>
                        </div>
                    </div>
                    <div className="col-lg-5 border-end p-3">
                        <h4>Informations personnelles</h4>
                        <hr className="hr w-50 mt-0 mb-3" />
                        <div className="form-group my-3">
                            <label htmlFor="prenoms" className="fs-5 fw-bold">Prenom(s)</label>
                            <input type="text" className="form-control" name="prenoms" id="prenoms" defaultValue={user.firstname} />
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="name" className="fs-5 fw-bold">Nom</label>
                            <input type="text" className="form-control" name="name" id="name" defaultValue={user.lastname} />
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="email" className="fs-5 fw-bold">Email</label>
                            <input type="email" className="form-control" name="email" id="email" defaultValue={user.email} />
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="telephone" className="fs-5 fw-bold">Téléphone</label>
                            <input type="number" className="form-control hide-arrow" name="telephone" id="telephone" defaultValue={user.phone} />
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="civilite" className="fs-5 fw-bold">Civilité</label>
                            <select name="civilite" id="civilite" className="form-select" defaultValue={user.civilite}>
                                <option value="">Faire un choix</option>
                                <option value="Marié">Marié</option>
                                <option value="Célibataire">Célibataire</option>
                            </select>
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="genre" className="fs-5 fw-bold">Genre</label>
                            <select name="genre" id="genre" className="form-select" defaultValue={user.sexe}>
                                <option value="">Faire un choix</option>
                                <option value="Homme">Homme</option>
                                <option value="Femme">Femme</option>
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button type="submite" className="btn btn-success">Enregistrer les modifications</button>
                        </div>
                    </div>
                    {user.droit == 'Magasin' && <div className="col-lg-5 p-3">
                        <h4>Informations Magasin</h4>
                        <hr className="hr w-50 mt-0 mb-3" />
                        <div className="form-group my-3">
                            <label htmlFor="prenoms" className="fs-5 fw-bold">CODE</label>
                            <input disabled type="text" className="form-control" value={profile.idmagasin} />
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="prenoms" className="fs-5 fw-bold">Magasin</label>
                            <input disabled type="text" className="form-control" value={profile.magasin} />
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="name" className="fs-5 fw-bold">Téléphone</label>
                            <input disabled type="text" className="form-control" value={profile.telephone} />
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="email" className="fs-5 fw-bold">Pays</label>
                            <input disabled type="text" className="form-control" value={profile.pays} />
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="email" className="fs-5 fw-bold">Ville</label>
                            <input disabled type="text" className="form-control" value={profile.ville} />
                        </div>
                        <div className="form-group my-3">
                            <label htmlFor="email" className="fs-5 fw-bold">Date affectation</label>
                            <input disabled type="text" className="form-control" value={(new Date(profile.date)).toLocaleDateString()} />
                        </div>
                    </div>}

                </div>

            </form>

        </div>
    );
}

export default Profile;