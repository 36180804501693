import { useEffect, useState } from "react";
import { decryptData, defaulUrl, Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import ModalAddClient from "../Clients/ModalAddClient";
import Select from "react-select";

const Facturation = () => {
    let id = decryptData('user_profile').idmagasin
    let { data: produits, isloading } = UseFetch(`mag/${id}/stocks`);
    let infoConteneur = produits?.map(p => ({ 'numero': p.numero, 'conteneur': p.conteneur }))
    const { data: clients, isloading: loader } = UseFetch(`clients/${id}`)
    infoConteneur = [...new Map(infoConteneur?.map(o => [o.numero, o])).values()]
    const [selected, setSelect] = useState([]);
    const [gonext, setGonext] = useState(false);
    const [isLoading, setIsloadind] = useState(false)
    const [client, setClient] = useState(null);
    const [total, setTotal] = useState(0);
    const [remise, setRemise] = useState(0);
    const [net, setNet] = useState(0);
    const [paye, setPaye] = useState(total - remise);
    const handSelect = (prod) => {
        if (!(selected.find(p => (p.id === prod.idstock && p.pvu === prod.pvu && prod.produit === p.produit)))) {
            setSelect([
                ...selected,
                {
                    'idproduit': prod.idproduit,
                    'produit': prod.produit,
                    'quantite': 1,
                    'pvu': prod.pvu,
                    'idstock': prod.id,
                    'qte_total': +prod.stock,
                    'idconteneur': prod.idconteneur
                }
            ]);
        }

    }
    const handleTotal = () => {
        setTotal(selected?.reduce(
            (previousValue, currentValue) => { return previousValue + currentValue.quantite * currentValue.pvu },
            0))
    }
    const handleSave = () => {
        if (!client) {
            Swal.fire({
                title: "Informations manquante!",
                text: "Veuillez entrer le client!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        let badIndex = -1;
        selected.forEach(prod => {
            if (prod.quantite > prod.qte_total) {
                badIndex = +selected.indexOf(prod) + 1
               
            }
        });
        
        if (badIndex >= 0) {
            Swal.fire({
                title: 'Quantite insuffisante',
                text: `Vous avez saisi une quantite superieure au stock sur la ligne ${badIndex}`,
                icon: "error"
            })
            return
        }
        let facture = {
            'montant': total,
            'remise': remise,
            'montant_net': net,
            'paye': paye
        }
        if (total <= remise) {
            Swal.fire({
                title: "Erreur sur la remise!",
                text: "Vérifiez le montant de la remise!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        if (net < paye) {
            Swal.fire({
                title: "Montant trop élévé!",
                text: "Vous avez saisi un montant supérieur au montant net à payer!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }
        setIsloadind(true)
        let status = 0
        fetch(`${defaulUrl}factures/add`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },
            body: JSON.stringify({ client, facture, selected })
        }).then(response => {
            status = response.status;

            return response.json();
        }).then(data => {
            console.log(data)
            if (status === 200) {
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                });
                handleReset();
            }
            setIsloadind(false)
        })
    }

    const handleQte = (e, prod) => {
        prod.quantite = +e.target.value
        handleTotal()

    }

    useEffect(() => {
        handleTotal();
    }, [selected])

    useEffect(() => {
        setNet(total - remise)
    }, [selected, total])

    const handleReset = () => {

        setTotal(0);
        setRemise(0);
        setNet(0);
        setPaye(0);
        setSelect([]);
        setGonext(false);
        setIsloadind(false);
        setClient(null)
    }

    return (
        <div className="Facturation container-fluid plage">
            {isLoading && <div className="loader"></div>}
            {selected.length > 0 && <div className="fst-itaclic h6 text-muted">{selected.length} produit(s) sélectionné(s)</div>}
            {!gonext && (
                isloading ? <Loading /> :
                    produits?.length ? infoConteneur && infoConteneur?.map((conteneur, ind) => (
                        <div className="row" key={ind}>

                            <div className="card my-2 p-1" >
                                <div className="card-header">
                                    <div className="card-title text-primary text-uppercase">{`${conteneur.numero} - ${conteneur.conteneur}`}</div>
                                </div>
                                <div className="card-body ">
                                    <div className="row cont">
                                        <div className="row p-2 ">
                                            {
                                                produits?.map((prod, index) => (
                                                    prod.numero === conteneur.numero && <div key={index} className="col-sm-3  shadow p-1 radius-15 m-0 prod">
                                                        <div className="card h-100 p-2 border-0 d-flex flex-column justify-content-center align-items-center m-0">
                                                            <span className="text-center prod-name">{prod.produit}</span>
                                                            <small className="fw-bold text-secondary">Qte: {prod.stock}</small>
                                                            <strong className="prix text-center">{`${prod.pvu.toLocaleString()} GNF`}</strong>
                                                            <button className="mt-auto btn btn-outline-success  justify-self-end" onClick={() => handSelect(prod)}>Ajouter</button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    ))
                        : <div className="row p-2 justify-content-center bg-danger-subtle text-danger fs-3">
                            <div className="clo-lg-6 p-2 text-center">
                                <img src="./images/empty.png" className="p-2 h-75 text-center w-50" alt=" image non dispo" />
                            </div>
                            Votre stock est actuellement vide !
                        </div>


            )}

            {gonext && <div className="row">
                <fieldset className="border rounded bg-dark-subtle">
                    <div className="row px-4 my-2" >
                        <div className="form-group  col-lg-6 positon-relative">
                            <label htmlFor="client" className="fs-4" >Client</label>
                            <div className="input-group">
                                {loader ? <Select /> :
                                    <Select className="w-75"
                                        defaultInputValue={client?.label}
                                        options={
                                            clients?.map((client) => (
                                                {
                                                    value: `${client.id}`, label: `${client.client}(${client.telephone})`
                                                }
                                            ))
                                        }
                                        onChange={setClient}
                                    />
                                }
                                <a className="input-group-text bg-transparent btn btn-primary text-dark" data-bs-toggle='modal' data-bs-target='#modalAddClient'>
                                    <i className='bx bx-plus-circle'></i>
                                </a>
                            </div>

                        </div>
                    </div>
                </fieldset>
                <div className="table-responsive p-2">
                    <table className="table table-white table-hover table-striped">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>PRODUITS</th>
                                <th>QTE STOCK</th>
                                <th>QTE</th>
                                <th>PVU</th>
                                <th>MONTANT</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selected?.map((prod, ind) => (
                                    <tr key={ind} className="align-middle">
                                        <td>{ind + 1}</td>
                                        <td>{prod.produit}</td>
                                        <td>{prod.qte_total}</td>
                                        <td>
                                            <input type="number" min={1} max={prod.qte_total} className="form-control" defaultValue={+prod.quantite} onChange={(e) => handleQte(e, prod)} />

                                        </td>
                                        <td>{(+prod.pvu).toLocaleString()}</td>
                                        <td>{(prod.pvu * prod.quantite).toLocaleString()}</td>
                                        <td className="text-end ">
                                            {/* {(prod.quantite < prod.qte_total) &&
                                                <i className="bx bx-plus mx-auto a p-1 text-success fs-5 bg-success-subtle rounded m-1" onClick={() => {
                                                    prod.quantite++
                                                    handleTotal()
                                                }}></i>} */}
                                            {/* {(prod.quantite > 1) &&
                                                <i className="bx bx-minus mx-auto a p-1 bg-warning-subtle rounded m-1  text-warning fs-5" onClick={() => {
                                                    if (prod.quantite > 1) {
                                                        prod.quantite--
                                                        handleTotal()
                                                    }
                                                }}></i>} */}
                                            <i className="bx bx-trash mx-auto a p-1  m-1 bg-danger-subtle rounded m-1 text-danger fs-5"
                                                onClick={() => {
                                                    setSelect(selected.filter(p => (p !== prod)))
                                                }}></i>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row p-2  bg-secondary-subtle">
                    <div className="col-lg-3 d-flex align-items-center my-1">
                        <div className="col-lg-3">
                            Total:
                        </div>
                        <div className="col-lg-6 grow-2  flex-grow-1">
                            <input type="text" disabled className="form-control" value={`${total.toLocaleString()} GNF`} />
                        </div>
                    </div>
                    <div className="col-lg-3 d-flex gap-1 align-items-center  my-1">
                        <div className="col-lg-3">
                            Remise:
                        </div>
                        <div className="col-lg-6 flex-grow-1">
                            <input type="number" className="form-control hide-arrow" defaultValue={remise} onChange={(e) => {
                                setRemise(+e.target.value)
                                setNet(+total - (+e.target.value))
                            }} />
                        </div>
                    </div>

                    <div className="col-lg-3 d-flex gap-1 align-items-center   my-1">
                        <div className="col-lg-3">
                            Net A payer:
                        </div>
                        <div className="col-lg-6 flex-grow-1">
                            <input type="text" disabled className="form-control" value={`${net.toLocaleString()} GNF`} />
                        </div>
                    </div>
                    <div className="col-lg-3 d-flex gap-1 align-items-center  my-1">
                        <div className="col-lg-3">
                            Payé:
                        </div>
                        <div className="col-lg-6 flex-grow-1">
                            <input type="number" className="form-control hide-arrow" defaultValue={paye} onChange={(e) => setPaye(+e.target.value)} />
                        </div>
                    </div>

                </div>
            </div>

            }
            <div className="row justify-content-end p-2 mt-5">
                <div className=" modal-footer">
                    {(!gonext && selected.length > 0) &&
                        <button className="btn btn-primary m-1 btn-card " onClick={() => setGonext(true)}>
                            <i className="bx bx-cart-alt fs-1 " ></i>
                            <span className="text-warning p-1 m-auto">{selected.length}</span>
                        </button>
                    }
                    {gonext && <button className="btn btn-danger m-1" onClick={() => setGonext(false)}> <i className="bx bx-first-page"></i> Retour
                    </button>}
                    {(selected.length > 0 && gonext) && <button className="btn btn-success m-1" onClick={() => handleSave()}>Enregistrer</button>}
                </div>

            </div>


            <ModalAddClient />
        </div>
    );
}

export default Facturation;