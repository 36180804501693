import { useRef, useState } from "react";
import { decryptData, defaulUrl, handleShowPwd, Loading } from "../components/const";
import Swal from "sweetalert2";
const Password = () => {
    const ref = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const user = decryptData('USER');
    const [error, setErrors] = useState(null)

    const handleModifier = (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = new FormData(e.target);
        let status = 0;
        fetch(`${defaulUrl}password/${user.id}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json'
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setIsLoading(false)
            if (status === 200) {
                Swal.fire({
                    title: "Success",
                    text: data.message,
                    icon: "success"
                });
                ref.current.click();
                setErrors(null)
            } else {
                setErrors(data.errors)
            }
        })
    }


    return (
        <div className="modal fade " id="modalPassword" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Changer mon mot de passe</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={(e) => handleModifier(e)}>

                        <div className="modal-body position-relative">
                            <label htmlFor="old">Ancien mot de passe</label>
                            <div className="input-group mb-2 show_hide_password" >
                                <input type="password" className="form-control border-end-0" id="old" placeholder="Entrez votre mot de passe " name="old" /> <a onClick={handleShowPwd} className="input-group-text bg-transparent btn btn-primary text-dark"><i className='bx bx-hide'></i></a>
                                {error?.old && <span className="text-danger  mb-2 w-100">{error?.old}</span>}
                            </div>
                            <label htmlFor="old">Nouveau mot de passe</label>
                            <div className="input-group mb-2 show_hide_password" >
                                <input type="password" className="form-control border-end-0" id="password" placeholder="Entrez votre mot de passe " name="password" /> <a onClick={handleShowPwd} className="input-group-text bg-transparent btn btn-primary text-dark"><i className='bx bx-hide'></i></a>
                                {error?.password && <span className="text-danger  mb-2 w-100">{error?.password}</span>}
                            </div>
                            <label htmlFor="old">Confirmation</label>
                            <div className="input-group show_hide_password" >
                                <input type="password" className="form-control border-end-0" id="confirm" placeholder="Entrez votre mot de passe " name="confirm" /> <a onClick={handleShowPwd} className="input-group-text bg-transparent btn btn-primary text-dark"><i className='bx bx-hide'></i></a>
                                {error?.confirm && <span className="text-danger  mb-2 w-100">{error?.confirm}</span>}
                            </div>

                            {isLoading && <span className="position-absolute"> <Loading /></span>}
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</a>
                            <button type="submite" className="btn btn-success" >Modifier</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default Password;