import { useEffect, useState } from "react";
import { decryptData, defaulUrl } from "../components/const";

const RapportAdminComptable = () => {
    const datejour = (new Date()).toISOString().slice(0, 10);
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const [isLoading, setIsloading] = useState(false);
    const [donnees, setData] = useState(null);
    const [selected, setSelected] = useState(2);
    const handleSend = () => {
        setIsloading(true);
        let status = 0;
        fetch(`${defaulUrl}admin/rapport-comptable/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsloading(false);
            if (status === 200) {
                setData(da)
                setSelected(2)
            }
        })
    };

    const handleActive = (e) => {
        const items = document.querySelectorAll('.link');
        items.forEach(item => {
            item.classList.remove('active');
        })
        e.target.classList.add('active')
    }
    console.log(donnees)
    useEffect(() => {
        handleSend()
    }, [])
    return (
        isLoading ? <div className="loader"></div> :
            <div className="rapport">
                <div className="row mb-2  p-3 rounded justify-content-between bg-white">
                    <div className="col-lg-6">
                        <div className="h4 text-primary">FASAN TEXTIL-COMPTABILITY</div>
                    </div>
                    <div className="bg-white mt-2  w-100 h5 p-2 text-secondary d-flex justify-content-between align-items-center">
                        <div className="col-lg-6 h4 text-secondary ">
                            Rapport périodique
                        </div>
                        <div className="col-lg-6  ">
                            <div className="modal-footer align-items-center justify-content-end ">
                                <div className="col-lg-4 m-1 text-end ">
                                    <input type="date" className="form-control " id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                                </div>
                                <div className="col-lg-4 m-1 text-end">
                                    <input type="date" className="form-control " id="date1" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                                </div>
                                <div className="col-lg-2  text-end w-auto">
                                    <button type="submit" className="btn btn-primary" onClick={() => handleSend()}><i className="bx bx-search"></i></button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0 bg-primary-subtle rounded">
                    <li hidden className=" fw-bold align-middle p-3   text-center w-auto link " onClick={(e) => { handleActive(e); setSelected(1) }}>TOUT</li>
                    <li className=" fw-bold align-middle p-3   text-center w-auto link active" onClick={(e) => { handleActive(e); setSelected(2) }}>RECEPTIONS</li>
                    <li className=" fw-bold align-middle p-3   text-center w-auto link" onClick={(e) => { handleActive(e); setSelected(3) }}>REMBOURSEMENTS</li>
                    <li className=" fw-bold align-middle p-3   text-center w-auto link" onClick={(e) => { handleActive(e); setSelected(4) }}>BANQUE</li>
                    <li className="fw-bold  align-middle p-3   text-center w-auto link" onClick={(e) => { handleActive(e); setSelected(5) }}>ENTREES</li>
                    <li className="fw-bold  align-middle p-3   text-center w-auto link" onClick={(e) => { handleActive(e); setSelected(6) }}> SORTIES</li>

                </div>
                {selected === 1 &&
                    <div className="table responsive mt-2" >
                        <table className="table table-striped table-hover table-border">
                            <thead className="table-dark">
                                <th>N°</th>
                                <th>MAGASIN</th>
                                <th>ENTRE</th>
                                <th>SORTIE</th>
                                <th>BALANCE</th>
                                <th>DATES</th>
                            </thead>
                        </table>
                    </div>
                }
                {selected === 2 && <div className="row ventes p-2 justify-content-center">
                    {donnees?.reception?.length ? <div className="table responsive mt-2" >
                        <table className="table table-striped table-hover table-border">
                            <thead className="table-dark">
                                <tr>
                                    <th>N°</th>
                                    <th>MAGASIN</th>
                                    <th>DESCRIPTION</th>
                                    <th>MONTANT</th>
                                    <th>DATES</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    donnees?.reception.map((recep, ind) => (
                                        <tr key={ind}>
                                            <td>{ind+1}</td>
                                            <td>{recep.magasin}</td>
                                            <td>{recep.description}</td>
                                            <td>{recep.montant.toLocaleString()}</td>
                                            <td>{(new Date(recep.created_at)).toLocaleDateString()}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucune reception effectuee</div>}
                </div>
                }
                {selected === 3 && <div className="row recouvrements p-2 justify-content-center">
                    {
                        donnees?.remboursement?.length ? <div className="table responsive mt-2" >
                            <table className="table table-striped table-hover table-border">
                                <thead className="table-dark">
                                    <tr>
                                        <th>N°FACT</th>
                                        <th>FOURNISSEUR</th>
                                        <th>MONTANT</th>
                                        <th>DATES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        donnees?.remboursement.map((remb, ind) => (
                                            <tr key={ind}>
                                                <td>{remb.numero}</td>
                                                <td>{remb.fournisseur}</td>
                                                <td>{remb.montant.toLocaleString()}</td>
                                                <td>{(new Date(remb.date)).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                        </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucun remboursement effectue</div>
                    }
                </div>
                }
                {selected === 4 && <div className="row entree p-2 justify-content-center">
                    {
                        donnees?.banque?.length ? <div className="table responsive mt-2" >
                            <table className="table table-striped table-hover table-border">
                                <thead className="table-dark">
                                    <tr>
                                        <th>N°</th>
                                        <th>MONTANT</th>
                                        <th>DESCRIPTIONS</th>
                                        <th>DATES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        donnees.banque.map((ent, ind) => (
                                            <tr key={ind}>
                                                <td>{ind + 1}</td>
                                                <td>{ent.montant.toLocaleString()}</td>
                                                <td>{ent.description}</td>
                                                <td>{(new Date(ent.created_at)).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucun versement effectue</div>
                    }
                </div>

                }
                {selected === 5 && <div className="row entree p-2 justify-content-center">
                    {
                        donnees?.entree?.length ? <div className="table responsive mt-2" >
                            <table className="table table-striped table-hover table-border">
                                <thead className="table-dark">
                                    <tr>
                                        <th>N°</th>
                                        <th>MONTANT</th>
                                        <th>DESCRIPTIONS</th>
                                        <th>DATES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        donnees.entree.map((ent, ind) => (
                                            <tr key={ind}>
                                                <td>{ind + 1}</td>
                                                <td>{ent.montant.toLocaleString()}</td>
                                                <td>{ent.description}</td>
                                                <td>{(new Date(ent.created_at)).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucune entrée effectuee</div>
                    }
                </div>

                }
                {selected === 6 && <div className="row sortie p-2 justify-content-center">
                    {
                        donnees?.sorties?.length ? <div className="table responsive mt-2" >
                            <table className="table table-striped table-hover table-border">
                                <thead className="table-dark">
                                    <tr>
                                        <th>N°</th>
                                        <th>MONTANT</th>
                                        <th>DESCRIPTIONS</th>
                                        <th>DATES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        donnees.sorties.map((ent, ind) => (
                                            <tr key={ind}>
                                                <td>{ind + 1}</td>
                                                <td>{ent.montant.toLocaleString()}</td>
                                                <td>{ent.description}</td>
                                                <td>{(new Date(ent.created_at)).toLocaleDateString()}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div> : <div className="row bg-danger-subtle text-danger fs-3 p-2 justify-content-center">Aucune sortie effectuee</div>
                    }
                </div>

                }

            </div>
    );
}

export default RapportAdminComptable;