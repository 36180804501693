import { useState, useEffect } from "react";
import { decryptData, defaulUrl } from "./const";
const UseFetch = (url) => {
    const [data, setData] = useState(null);
    const [isloading, setIsloadind] = useState(true);
    let status = 0;
    useEffect(() => {
        fetch(`${defaulUrl}${url}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
             setIsloadind(false);
            if (status === 200) {
                setData(da)
            }
        })
      
    }, [data, url]);
    return { data, isloading }
}


export default UseFetch;