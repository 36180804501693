import { Navigate, NavLink, useLocation, useNavigate,  useParams } from "react-router-dom";
import { Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
const DetailAppro = ({ iStock }) => {
    let { id } = useParams()
    const navigator = useNavigate()
    const { data: table, isloading } = UseFetch(`stocks-magasins-appro/${+id}`)
    return (
        <div className="AddModal">
            <div className="row justify-content-between">
                <div className="col-lg-3">
                    <button className="btn btn-primary" onClick={() => navigator(-1)}  ><i className="bx bx-arrow-back"></i> Retour</button>
                </div>
                <div className="h3 text-primary col-lg-6 text-end">LISTE DES PRODUITS DU CONTENEUR</div>
            </div>
            <div className="">

                <div className="row p-2 table-responsive mt-2">
                    {isloading ? <Loading /> :
                        <table className="table table-striped table-hover shadow">
                            <thead className="table-secondary">
                                <tr>
                                    <th>N°</th>
                                    <th>Produit</th>
                                    <th>quantite</th>
                                    <th>PAU</th>
                                    <th>Mont. Achat</th>
                                    <th>PVU</th>
                                    <th>Mont. Vente</th>
                                    <th>qte vendu</th>
                                    <th>qte restante</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    table.map((prod, index) => (
                                        <tr key={index + 1}>
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td>{prod.produit}</td>
                                            <td>{prod.quantite}</td>
                                            <td>{`${(prod.pau.toLocaleString())} ${prod.devise}`}</td>
                                            <td>{`${((+prod.pau) * (+prod.quantite)).toLocaleString()} ${prod.devise}`}</td>
                                            <td>{`${prod.pvu.toLocaleString()} GNF`}</td>
                                            <td>{`${((+prod.pvu) * (+prod.quantite)).toLocaleString()} GNF`}</td>
                                            <td>{prod.vendu}</td>
                                            <td>{+prod.quantite - (+prod.vendu)}</td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                </div>


            </div>

        </div>
    );
}

export default DetailAppro;