import { useState } from "react";
import UseFetch from "../components/UseFetch";
import { decryptData, defaulUrl, Loading } from "../components/const";
import { useRef } from "react";
import Swal from "sweetalert2";
import SearchField from "../components/SeachField";
const Categories = () => {

    let [isLoading, setIsLoading] = useState(false);
    const useref = useRef()
    const [errors, setErrors] = useState({});
    const { data: categories, isloading } = UseFetch('categories/liste');
    let status = 0;

    const handleSubmite = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formData = new FormData(e.target);
        fetch(`${defaulUrl}categories/add`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json'
            },
            body: formData
        }).then((response) => {
            console.log(response)
            status = response.status;
            return response.json();
        }).then(data => {

            if (status === 200) {
                setIsLoading(false);
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                }).then(close => {
                    useref.current.click()
                })
                setErrors({});
            } else {
                setErrors(data.errors)
            }

        })

    }
    const handleDelete = (e, id) => {
        e.preventDefault();
        Swal.fire({
            title: "Suppression",
            text: "Vous allez supprimer définitivement cette categorie?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            confirmButtonColor: "red",
            cancelButtonColor: "blue"
        }).then((resp) => {
            if (resp.isConfirmed) {
                fetch(`${defaulUrl}categories/delete/${id}`, {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + decryptData('ACCESS_TOKEN'),
                        Accept: 'application/json'
                    }
                }).then((response) => {
                    status = response.status;
                    return response.json();
                }).then((data) => {
                    if (status === 200) {
                        Swal.fire({
                            title: "Suppression de compte",
                            text: data.message,
                            icon: "success"
                        })
                    }
                })
            }
        })
    }

    return (
        <div className="Categories container-fluid ">
            <div className="row my-2">
                <div className="col-lg-8 h2 text-primary">
                    GESTION DES CATEGORIES
                </div>
                <div className="col-lg-4 text-end">
                    <button type="button" className="btn btn-primary " data-bs-toggle="modal" data-bs-target="#exampleModal" >Ajouter</button>
                </div>
            </div>
            {
                isloading ? <div className="row p-2"><Loading /></div>
                    : <div className="table-responsive p-0 ">
                        <div className="row justify-content-end m-0">
                            <div className="col-lg-4 py-2">
                                <SearchField plage='categories' conteneur='tr' critere='categorie' />
                            </div>
                        </div>
                        <table className="table table-white shadow table-bordered table-hover table-striped">

                            <thead className="table-dark">
                                <tr>
                                    <th hidden>#ID</th>
                                    <th>#ID</th>
                                    <th>CATEGORIES</th>
                                    <th>DATE CREATION</th>
                                    <th>DATE MODIF</th>
                                    <th className="text-end">ACTIONS</th>
                                </tr>
                            </thead>
                            {
                                categories ?

                                    <tbody className="categories">

                                        {
                                            categories.map((cat, index) => (
                                                <tr key={index} className="tr">
                                                    <td hidden>{cat.idcategorie}</td>
                                                    <td>{index + 1}</td>
                                                    <td className="categorie">{cat.categorie}</td>
                                                    <td>{new Date(cat.created_at).toLocaleDateString()}</td>
                                                    <td>{new Date(cat.updated_at).toLocaleDateString()}</td>
                                                    <td className="text-end">
                                                        <button className="btn btn-danger m-1" onClick={(e) => handleDelete(e, cat.id)}>Supp.</button>
                                                    </td>
                                                </tr>
                                            ))

                                        }

                                    </tbody>
                                    : <tbody>
                                        <tr className="text-center bg-danger-subtle ">
                                            <td colSpan="5" className="p-3 fs-4">Aucune categorie trouvée!</td>
                                        </tr>
                                    </tbody>
                            }

                        </table>
                    </div>
            }



            {/* Modal */}
            <div className="AddModal">
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Ajouter une Categorie</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form className=" p-2 rounded " onSubmit={handleSubmite}>
                                    <div className="row">
                                        <div className="col p-1">
                                            <div className="form-group">
                                                <label htmlFor="categorie" className=" fs-4 fw-800">Categorie</label>
                                                <input type="text" name="categorie" id="categorie" className="form-control" placeholder="Entrez la categorie" />
                                            </div>
                                            {errors.categorie && <span className="text-danger mt-1">{errors.categorie}</span>}
                                        </div>
                                    </div>
                                    <div className=" p-2 text-end m-2">
                                        <a ref={useref} data-bs-dismiss="modal" className="btn btn-danger">Annuler</a>
                                        <button type="submit" className="btn btn-success mx-1">Enregistrer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Fin modal */}
        </div>

    );




}

export default Categories;