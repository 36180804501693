import { Loading } from "../components/const";

const DetailConteneur = ({ isLoading, detailproduct }) => {
    return (
        <div className="AddModal">
            <div className="modal fade" id="detailConteneur" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Liste des produit du conteneur</h1>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">

                            <div className="row p-2 table-responsive">
                                {isLoading ? <Loading /> : <table className="table table-stripped">
                                    <thead>
                                        <tr>
                                            <th>N°</th>
                                            <th>Produit</th>
                                            <th>quantite</th>
                                            <th>Prix Unitaire</th>
                                            <th>Montant</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            detailproduct.map((prod, index) => (
                                                <tr key={index + 1}>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>{prod.produit}</td>
                                                    <td>{prod.quantite}</td>
                                                    <td>{`${prod.prix.toLocaleString()} GNF`}</td>
                                                    <td>{`${prod.montant.toLocaleString()} GNF`}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                                }
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default DetailConteneur;