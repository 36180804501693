import { useEffect, useRef, useState } from "react";
import { decryptData, defaulUrl } from "../components/const";
import OperationsMagasin from "../Comptability/ModalOpaerationMagasin";

const DbMagasin = () => {
    const profile = decryptData('user_profile');
    const date = new Date();
    const [isloading, setIsloading] = useState(false);
    const [datejour, setDateJour] = useState(date.toISOString().slice(0, 10));
    const [date1, setDate1] = useState(datejour);
    const [date2, setDate2] = useState(datejour);
    const [dashboard, setDashboard] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [titre, setTitre] = useState(null);//POUR LES OPERATIONS
    const [type, setType] = useState(null);//POUR LES OPERATIONS
    const refBtnSend = useRef();
    const global = dashboard?.global
    const periode = dashboard?.periode
    const handleSend = () => {
        setIsloading(true);
        let status = 0;
        fetch(`${defaulUrl}dashboard/magasin/${date1}/${date2}`, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            }
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(da => {
            setIsloading(false);
            if (status === 200) {
                setDashboard(da)
            }
        })
    };
    const handleShowModalOperation = (title, type) => {
        setTitre(title);
        setType(type)
    }

    console.log(dashboard)
    useEffect(() => {
        if (firstLoad) {
            handleSend();
            setFirstLoad(false)
        }
    }, [firstLoad])

    return (
        <div className="dashboard">


            {(!dashboard || isloading) && <div className="loader"></div>}
            <div className="dboardMag">
                <div className="row mb-2  p-3 rounded justify-content-between bg-white">
                    <div className="col-lg-6">
                        <div className="h4 text-primary">FASAN TEXTIL-{profile.magasin.toUpperCase()}</div>
                        <span className="text-secondary fs-5">Tableau de bord</span>
                    </div>

                </div>
                <div className="bg-white mt-2  w-100 h5 p-2 text-secondary d-flex justify-content-between align-items-center">
                    <div className="col-lg-6 h4 text-secondary ">
                        Rapport periodique
                    </div>
                    <div className="col-lg-6  ">
                        <div className="modal-footer align-items-center justify-content-end ">
                            <div className="col-lg-4 m-1 text-end ">
                                <input type="date" className="form-control " id="date1" name="date1" defaultValue={date1} onChange={(e) => setDate1(e.target.value)} />
                            </div>
                            <div className="col-lg-4 m-1 text-end">
                                <input type="date" className="form-control " id="date1" name="date2" defaultValue={date2} onChange={(e) => setDate2(e.target.value)} />
                            </div>
                            <div className="col-lg-2  text-end w-auto">
                                <button ref={refBtnSend} type="submit" className="btn btn-primary" onClick={() => handleSend()}><i className="bx bx-search"></i></button>
                            </div>
                        </div>

                    </div>

                </div>
                {dashboard && <div className="row">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 shadow rounded p-2">

                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-primary ">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="row">
                                            <p className="mb-0 text-secondary">Total factures</p>
                                            <h5 className="my-1  text-primary">{(+periode?.total).toLocaleString()} GNF</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-info">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="row">
                                            <p className="mb-0 text-secondary">Factures payées</p>
                                            <h5 className="my-1  text-info">{(+periode?.paye).toLocaleString()} GNF</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-warning">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Recouvrement clients</p>
                                            <h5 className="my -1 text-warning">{(+periode?.remboursement).toLocaleString()} GNF</h5>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1" hidden>
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-primary">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Solde clients</p>
                                            <h5 className="my -1 text-primary">{(+periode?.solde).toLocaleString()} GNF</h5>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-warning">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="w-100">
                                            <p className="mb-0 text-secondary">Entrées</p>
                                            <h5 className="my -1 text-warning">{(+periode?.entree).toLocaleString()} GNF</h5>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-danger">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Dettes</p>
                                            <h5 className="my-1  text-danger">{(+periode?.dette).toLocaleString()} GNF</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-danger">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="w-100">
                                            <p className="mb-0 text-secondary">Sorties</p>
                                            <h5 className="my-1  text-danger">{(+periode?.sortie).toLocaleString()} GNF</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-success text-success bg-success-subtle">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0">Solde periodique</p>
                                            <h5 className="my-1  text-success">

                                                {
                                                    (
                                                        ((+periode?.paye + (+ periode?.entree) + (+ periode?.remboursement))
                                                            - ((+periode?.sortie)))).toLocaleString()


                                                } GNF


                                            </h5>
                                        </div>
                                        <div className="widgets-icons-2 rounded-circle bg-success p-3 ms-auto"><img src="./images/solde.png" alt="" className="w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-secondary">
                                <div className="py-4 card-body">
                                    <button className="btn btn-outline-primary m-1" data-bs-toggle='modal' data-bs-target='#modalOprationMagasin' onClick={() => handleShowModalOperation('Enregistrer une entrée de caisse', 'entree')}><i className="bx bx-plus-circle"></i>Saisir une Entrée</button>
                                    <button className="btn btn-outline-primary m-1" data-bs-toggle='modal' data-bs-target='#modalOprationMagasin' onClick={() => handleShowModalOperation('Enregistrer une sortie de caisse', 'sortie')}><i className="bx bx-plus-circle"></i>Saisir une Sortie</button>
                                    <button className="btn btn-outline-primary m-1" data-bs-toggle='modal' data-bs-target='#modalOprationMagasin' onClick={() => handleShowModalOperation('Faire un versement', 'comptabilite')}><i className="bx bx-plus-circle"></i>Faire un Versement</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 shadow rounded p-2">
                        <h4 className="text-secondary mt-2 w-100 py-3 bg-white">Rapport global</h4>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-primary">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Total Factures</p>
                                            <h5 className="my-2  text-primary">
                                                {global ? `${(+global?.total).toLocaleString()} GNF` : ''} </h5>
                                        </div>
                                        <div className="widgets-icons-2 p-3 rounded-circle bg-primary text-white ms-auto"><i className='bx bx-calculator fs-1'></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-info">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Factures payées</p>
                                            <h5 className="my-2  text-info">
                                                {global ? `${(+global?.paye).toLocaleString()} GNF` : ''}</h5>
                                        </div>
                                        <div className="widgets-icons-2 p-3 rounded-circle bg-info text-white ms-auto"><img src="./images/receive-money.png" alt="" className="w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1" hidden>
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-warning">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Solde Clients</p>
                                            <h5 className="my -1 text-warning">  {global ? `${(+global.solde).toLocaleString()} GNF` : ''}</h5>

                                        </div>
                                        <div className="widgets-icons-2 rounded-circle bg-warning text-white ms-auto p-3"><img src="./images/receive-money.png" alt="" className="w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-warning">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Total Entrées</p>
                                            <h5 className="my -1 text-warning"> {global ? `${(+global?.entree).toLocaleString()} GNF` : ''}</h5>

                                        </div>
                                        <div className="widgets-icons-2 rounded-circle bg-warning text-white ms-auto p-3"><img src="./images/receive-money.png" alt="" className="w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-danger">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Factures impayées</p>
                                            <h5 className="my-1  text-danger">{global ? `${(+global?.dette).toLocaleString()} GNF` : ''}</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-danger">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Total sorties</p>
                                            <h5 className="my -1 text-danger"> {global ? `${(+global?.sortie).toLocaleString()} GNF` : ''}</h5>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-danger">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-secondary">Versements comptabilité</p>
                                            <h5 className="my-1  text-danger"> {global ? `${(+global?.versement).toLocaleString()} GNF` : ''}</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col my-1">
                            <div className="card h-100 radius-10 border-start border-0 border-3 border-success bg-success-subtle">
                                <div className="py-4 card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <p className="mb-0 text-success fs-4 fw-bold">Solde Caisse</p>
                                            <h5 className="my-1  text-success">
                                                {
                                                    (
                                                        ((+global?.paye + (+ global?.solde) + (+ global?.entree))
                                                            - (+global?.versement + (+global?.sortie)))).toLocaleString()


                                                } GNF
                                            </h5>
                                        </div>
                                        <div className="widgets-icons-2 rounded-circle bg-success text-white p-3 ms-auto"><img src="./images/solde.png" alt="" className="w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                }
            </div>
            <OperationsMagasin titre={titre} type={type} />
        </div>

    );
}

export default DbMagasin;