import { useState } from "react";
import { Loading, decryptData, defaulUrl } from "../components/const";
import UseFetch from "../components/UseFetch";
import { useRef } from "react";
import Swal from "sweetalert2";
import SearchField from "../components/SeachField";
const AddProduct = () => {
    let [produit, setProduit] = useState('');
    let [errors, setErrors] = useState({});
    let status = 0;
    const useref = useRef()
    let [isLoading, setIsLoading] = useState(false);
    const { data: produits, isloading: load } = UseFetch('produits/liste')


    const handleSubmite = (e) => {
        setIsLoading(true)
        e.preventDefault();
        const formdata = new FormData();
        formdata.set('produit', produit);
        fetch(`${defaulUrl}produits/add`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json'
            },
            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setIsLoading(false)
            if (status === 200) {
                setIsLoading(false);
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                }).then(close => {
                    useref.current.click()
                    setProduit('')
                })

                setErrors({});
            } else {
                setErrors(data.errors)
            }
        })



    }
    return (
        <div className="Prdouit container-fluid ">
            <div className="row my-2">
                <div className="col-lg-8 h2 text-primary">
                    GESTION DES PRODUITS
                </div>
                <div className="col-lg-4 text-end">
                    <button type="button" className="btn btn-primary " data-bs-toggle="modal" data-bs-target="#exampleModal" >Nouveau produit</button>
                </div>
            </div>
            {load ? <Loading /> : produits.length ? <div className="row ">
                <div className="row my-2 justify-content-end">
                    <div className="col-lg-4 text-end">
                        <SearchField plage='plage' conteneur='tr' critere='produit' />
                    </div>
                    <div className="table-responsive">
                        <table className="table table-white shadow table-bordered table-hover table-striped">
                            <thead className="table-primary">
                                <tr >
                                    <th hidden>#ID</th>
                                    <th>N°</th>
                                    <th>Produit</th>
                                    <th>Date creation</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="plage">
                                {
                                    produits.map((p, i) => (
                                        <tr key={p.id} className="tr">
                                            <td hidden>{p.id}</td>
                                            <td>{i + 1}</td>
                                            <td className="produit">{p.produit}</td>
                                            <td>{new Date(p.created_at).toLocaleDateString()}</td>
                                            <td className="text-end">
                                                <button className="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#exampleModal">Edite</button>
                                                <button className="btn btn-danger m-1">Supp.</button>
                                            </td>
                                        </tr>
                                    ))


                                }

                            </tbody>
                        </table>


                    </div>
                </div>

            </div>
                : <div className="p-2 row justify-content-center bg-danger-subtle text-danger fs-3">
                    Aucun produit trouvé!
                </div>
            }
            {/* Modal */}
            <div className="AddModal">
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Ajouter un produit</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form className=" p-2 rounded " onSubmit={handleSubmite} >
                                    <div className="row my-2">
                                        <div className="col-12 p-1">
                                            <div className="form-group">

                                                <label htmlFor="produit" className=" fs-4 fw-800 text-dark">Libelé Produit</label>
                                                <input type="text" name="produit" id="produit" className="form-control p-3" placeholder="Entrez le libele du produit" onChange={(e) => setProduit(e.target.value)} value={produit} />
                                            </div>
                                        </div>
                                        {errors.produit && <span className="text-danger">{errors.produit}</span>}
                                    </div>

                                    <div className="row justify-content-end align-items-center p-2 text-end m-2">
                                        <div className="col-lg-6 d-flex justify-content-end">
                                            <a ref={useref} data-bs-dismiss="modal" className="btn btn-danger">Annuler</a>
                                            <button type="submit" className="btn btn-success mx-2 d-flex align-items-center ">{isLoading && <Loading />} Enregistrer</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Fin modal */}
        </div>
    );
}

export default AddProduct;