import { useEffect, useState } from "react";
import { decryptData, defaulUrl, Loading } from "../components/const";
import UseFetch from "../components/UseFetch";
import OperationsMagasin from "./ModalOpaerationMagasin";
import OperationsComptable from "./ModalOpaerationsComptable";
const DbComptable = () => {
    const { data, isloading: loader } = UseFetch(`dashboard/comptable`);
    const [titre, setTitre] = useState(null);//POUR LES OPERATIONS
    const [type, setType] = useState(null);//POUR LES OPERATIONS
    const operations = data?.operations;

    const handleShowModalOperation = (title, type) => {
        setTitre(title);
        setType(type)
    }
    // const handleSend = (e) => {
    //     setIsloading(true);
    //     let status = 0;
    //     fetch(`${defaulUrl}dashboard/comptable`, {
    //         method: "GET",
    //         headers: {
    //             Accept: 'application/json',
    //             Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
    //         }
    //     }).then((response) => {
    //         status = response.status;
    //         return response.json()
    //     }).then(da => {
    //         setIsloading(false);
    //         if (status === 200) {
    //             setDashboard(da)
    //         }
    //     })
    // };

    return (
        loader ? <div className="loader"></div> : <div className="dboardMag">
          
            <div className="row mb-2  p-3 rounded justify-content-between bg-white">
                <div className="col-lg-6">
                    <div className="h4 text-primary">FASAN TEXTIL-COMPTABILITY</div>
                    <span className="text-muted fs-5">Tableau de bord</span>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 shadow rounded p-2">
                <div className="col my-1">
                    <div className="card h-100 radius-10 border-start border-0 border-3 border-primary">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-secondary">Solde initial</p>
                                    <h5 className="my-1  text-primary">{(operations?.initial).toLocaleString()} GNF</h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col my-1">
                    <div className="card h-100 radius-10 border-start border-0 border-3 border-info">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-secondary">Encaissements du jour</p>
                                    <h4 className="my -1 text-info">{(operations.encaissement).toLocaleString()} GNF</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col my-1">
                    <div className="card h-100 radius-10 border-start border-0 border-3 border-warning ">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-secondary">Entrées du jour</p>
                                    <h5 className="my-1  text-warning">{(operations.entree).toLocaleString()} GNF</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col my-1">
                    <div className="card h-100 radius-10 border-start border-0 border-3 border-danger">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-danger">Remboursements founisseur</p>
                                    <h5 className="my-1  text-danger">{(operations.remboursement).toLocaleString()} GNF</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col my-1">
                    <div className="card h-100 radius-10 border-start border-0 border-3 border-danger">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-danger">Sorties du jour</p>
                                    <h5 className="my-1  text-danger">{(operations.sortie).toLocaleString()} GNF</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col my-1">
                    <div className="card h-100 radius-10 border-start border-0 border-3 border-danger">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-danger">Versements à la banque</p>
                                    <h5 className="my-1  text-danger">{(operations.banque).toLocaleString()} GNF</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col my-1">
                    <div className="card h-100 radius-10 border-start border-0 border-3 border-success bg-success-subtle">
                        <div className="py-4 card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <p className="mb-0 text-success fs-4 fw-bold">Solde à reporter</p>
                                    <h5 className="my-1  text-success">{(operations.solde).toLocaleString()} GNF</h5>
                                </div>
                                <div className="widgets-icons-2 rounded-circle bg-success text-white p-3 ms-auto"><img src="./images/solde.png" alt="" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col my-1">
                    <div className="card h-100 radius-10 border-start border-0 border-3 border-secondary">
                        <div className="modal-footer justify-content-start bg-secondary-subtle rounded">
                            <button className="btn btn-outline-primary m-1" data-bs-toggle='modal' data-bs-target='#modalOprationMagasin' onClick={() => handleShowModalOperation('Enregistrer une entrée de caisse', 'entree')}><i className="bx bx-plus-circle"></i>Entrée</button>
                            <button className="btn btn-outline-primary m-1" data-bs-toggle='modal' data-bs-target='#modalOprationMagasin' onClick={() => handleShowModalOperation('Enregistrer une sortie de caisse', 'sortie')}><i className="bx bx-plus-circle"></i>Sortie</button>
                            <button className="btn btn-outline-primary m-1" data-bs-toggle='modal' data-bs-target='#modalcomptable' onClick={() => handleShowModalOperation('Encaisser un versement magasin', 'encaissement')}><i className="bx bx-plus-circle"></i>Encaissement magasins</button>
                            <button className="btn btn-outline-primary m-1" data-bs-toggle='modal' data-bs-target='#modalOprationMagasin' onClick={() => handleShowModalOperation('Faire un versement banquaire', 'banque')}><i className="bx bx-plus-circle"></i>Versement banque</button>
                        </div>
                    </div>
                </div>
            </div>


            <OperationsMagasin titre={titre} type={type} droit='comptable' />
            <OperationsComptable titre={titre} type={type} />
        </div>
    );
}


export default DbComptable;