
import CryptoJS from "crypto-js";
import { Navigate, redirect } from "react-router-dom";
import Swal from "sweetalert2";

export const handleShowPwd = (e) => {
    e.preventDefault();
    let pwdInput = document.querySelectorAll('.show_hide_password input');
    let pwdIcon = document.querySelectorAll('.show_hide_password .bx');

    pwdInput.forEach(input => {
        if (input.getAttribute('type') === 'text') {
            input.setAttribute('type', 'password');
            pwdIcon.forEach(icon => {
                icon.classList.add('bx-hide');
                icon.classList.remove('bx-show');
            })
        } else {
            input.setAttribute('type', 'text');

            pwdIcon.forEach(icon => {
                icon.classList.remove('bx-hide');
                icon.classList.add('bx-show');
            })
        }
    })

}
// export const defaulUrl = 'https://www.avenaguinee.com/appstock/public/api/';

// export const defaulUrl = 'http://127.0.0.1:8000/api/';
// export const photoUrl = 'http://127.0.0.1:8000/upload/'
export const defaulUrl = 'https://www.fasantextil.com/backend_magasin/public/api/';
export const photoUrl='https://www.fasantextil.com/backend_magasin/public/upload/'

export const Loading = () => {
    return (
        <div className="Loading py-2 my-2">
            <div className="col-12 ">
                <div className="d-flex justify-content-center ">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden me-2">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const token = localStorage.getItem('ACCESS_TOKEN');

export const saveFournisseur = (nom, prenoms, telephone, adresse) => {
    localStorage.removeItem('Fournisseur');
    localStorage.setItem('Fournisseur', JSON.stringify({
        'prenoms': prenoms,
        'nom': nom,
        'telephone': +telephone,
        'adresse': adresse
    }))
}
export const getFournisseur = JSON.parse(localStorage.getItem('Fournisseur'));

export const deleteLocalStorage = () => {
    localStorage.removeItem("Fournisseur");
    localStorage.removeItem("storedInfo");
    localStorage.removeItem("selectedProducts");
}

const myencrypteKey = 'encrypion';
export const encryptData = (name, data) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), myencrypteKey).toString();
    localStorage.setItem(name, encrypted);
}
export const decryptData = (name) => {
    const encrypted = localStorage.getItem(name);
    if (encrypted) {
        const decrypted = CryptoJS.AES.decrypt(encrypted, myencrypteKey).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    }
    return null
}
export const handeOnHoverSideBar = () => {
    const sidebar = document.querySelector('.toggled');
    if (sidebar) {
        sidebar.classList.add('sidebar-hovered');
    }
}
export const handeLeaveSideBar = () => {
    const sidebar = document.querySelector('.toggled');
    if (sidebar) {
        sidebar.classList.remove('sidebar-hovered');
        const ul_list = document.querySelectorAll('ul.show');
        if (ul_list) {
            ul_list.forEach(elem => {
                elem.classList.remove('show');
            })
        }
    }
}

export const deleter = (url) => {

    let status = 0;
    Swal.fire({
        title: "Suppression de donnees",
        text: "Vous voulez vraiment supprimer cet element",
        icon: 'question',
        showCancelButton: "true",
    }).then(conf => {
        if (conf.isConfirmed) {
            fetch(`${defaulUrl}${url}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`,
                    Accept: 'Application/json'
                }
            }).then(response => {
                status = response.status;
                return response.json();
            }).then(data => {
                if (status === 200) {
                    console.log(data);
                    Swal.fire({
                        timer: 3000,
                        title: "Succes",
                        text: data.message,
                        icon: 'success'
                    }).then(() => {
                    })
                }
            })
        }
    })

}