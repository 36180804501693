import { NavLink } from "react-router-dom";
const GetPwd = () => {
    return (
        <div className="Login bg-login">
            <div className="wrapper">
                <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                    <div className="container-fluid">
                        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                            <div className="col mx-auto">
                                <div className="mb-4 text-center">
                                    <img src="../assets/images/logo-img.png" width="180" alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="border p-4 rounded">
                                            <div className="text-center">
                                                <h3 className="">Récuperer mon mot de passe</h3>
                                            </div>

                                            <div className="login-separater text-center mb-4"> <span>VOTRE EMAIL</span>
                                                <hr />
                                            </div>
                                            <div className="form-body">
                                                <form className="row g-3">
                                                    <div className="col-12">
                                                        <label htmlFor="email" className="form-label"> Adresse Email</label>
                                                        <input type="email" className="form-control" id="email" placeholder="adresse email ou contact " />
                                                    </div>

                                                    <div className="col-md-12 text-end">	<NavLink to='/login'>Retour à la page de connexion?</NavLink>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button type="submit" className="btn btn-primary"><i className="bx bxs-send"></i>Envoyer</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default GetPwd;