import { Loading } from "../components/const";

const Versement = ({ isLoading, versements }) => {
    return (
        <div className="modal fade" id="modalVersement" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Détail des versements  </h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {
                            isLoading ? <Loading /> : versements?.length > 0 ?
                                <div className="table-responsive">
                                    <table className="table-table-hover table bordered table striped">
                                        <thead className="table-primary">
                                            <tr>
                                                <th>N°</th>
                                                <th>MONTANT</th>
                                                <th>DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                versements.map((vers, ind) => (
                                                   <tr key={ind}>
                                                        <td>{ind + 1}</td>
                                                        <td>{vers?.montant?.toLocaleString()}</td>
                                                        <td>{(new Date(vers?.created_at)).toLocaleDateString()}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div> : <div className="row p-2 bg-danger-subtle text-danger justify-content-center">Aucun versements effectué par ce client!</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Versement;