import UseFetch from "../components/UseFetch";
const Benefice = () => {
    const { data: benefice, isloading } = UseFetch('admin/benefice')


    console.log(benefice)
    return (
        isloading ? <div className="loader"></div> : benefice?.length ? <div className="benefice">
            <div className="row mb-2  p-3 rounded justify-content-between bg-white">
                <div className="col-lg-6">
                    <div className="h4 text-primary">FASAN TEXTIL-COMPTABILITY</div>
                </div>
                <div className="bg-white mt-2  w-100 h5 p-2 text-secondary d-flex justify-content-between align-items-center">
                    <div className="col-lg-6 h4 text-secondary ">
                        Benefice
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-hover table-striped">
                    <thead className="table-dark">
                        <tr className="align-middle text-center">
                            <th>N°</th>
                            <th className="text-start">CONTENEUR</th>
                            <th>MONTANT ACHAT</th>
                            <th>MT.VENTE</th>
                            <th>MT.VENDU</th>
                            <th>MT.STOCK</th>
                            <th>BENEFICE</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            benefice.map((ben, ind) => (
                                <tr key={ind} className="align-middle ">
                                    <td>{ind + 1}</td>
                                    <td>{ben.conteneur}</td>
                                    <td className="text-center">{ben.total.toLocaleString()}</td>
                                    <td className="text-center">{ben.totalvente.toLocaleString()}</td>
                                    <td className="text-center">{ben.totalvendu.toLocaleString()}</td>
                                    <td className="text-center">{(ben.totalvente-ben.totalvendu).toLocaleString()}</td>
                                    <td className="text-center">{(ben.totalvente-(ben.total)).toLocaleString()}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div> : <div className="w-100 p-2 bg-danger-subtle text-danger fs-3 text-center"> Aucun benefice</div>
    );
}

export default Benefice;