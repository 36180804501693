import { useRef, useState } from "react";
import { defaulUrl, Loading, decryptData } from "../components/const";
import Swal from "sweetalert2";
import UseFetch from "../components/UseFetch";
import SearchField from "../components/SeachField";
const Ville = () => {

    let [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    let status = 0;
    const { data: pays, isloading } = UseFetch('pays/liste')
    const { data: ville } = UseFetch('villes/liste')
    const ref = useRef()
    const handleDelete = (e) => {

    }
    const handleSubmite = (e) => {
        setIsLoading(true)
        e.preventDefault();
        const formData = new FormData(e.target);
        fetch(`${defaulUrl}villes/add`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json'
            },
            body: formData
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setIsLoading(false);
            if (status === 200) {
                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                })
                setErrors({});
                ref.current.click();
            } else {
                setErrors(data.errors)
            }
        })
    }
 
    return (
        <div className="Ville">
            <div className="row p-2 my-2 justify-content-between">
                <div className="col-lg-6 ">
                    <h1 className="text-primary">GESTION DES VILLES</h1>
                </div>
                <div className="col-lg-3 text-end">
                    <button className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#modalVille'>Ajouter une ville</button>
                </div>
            </div>



            <div className="row p-2 my-2">
                <div className="row justify-content-end">
                    <div className="col-lg-4">
                        <SearchField plage="plage" conteneur="tr" critere="pays" />
                    </div>
                    <div className="table-responsive mt-2">
                        <table className="table table-white shadow table-bordered table-hover table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th hidden>#ID</th>
                                    <th>#ID</th>
                                    <th>Ville</th>
                                    <th>Pays</th>
                                    <th>Date ajout</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="plage">
                                {isloading ? <tr><td colSpan="5" className="p-3"> <Loading /></td></tr> :
                                    ville ? ville.map((pa, index) => (
                                        <tr key={pa.id} className="tr">
                                            <td hidden> {pa.id}</td>
                                            <td> {index + 1}</td>
                                            <td> {pa.ville}</td>
                                            <td className="pays"> {pa.pays}</td>
                                            <td> {new Date(pa.created_at).toLocaleDateString()}</td>
                                            <td className="text-end">
                                                <button className="btn btn-primary m-1" disabled>Editer</button>
                                                <button className="btn btn-danger m-1" onClick={(e) => handleDelete(e, pa.id)}>Supp.</button>
                                            </td>
                                        </tr>
                                    )) : <tr><td rowSpan="4"> Aucun pays enregistré!</td></tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div className="modal fade " id="modalVille" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-secondary-subtle">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Ajouter un pays</h1>
                            <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={(e) => handleSubmite(e)} >
                            <div className="modal-body position-relative">
                                <div className="form-group ">
                                    <label htmlFor="pays" className="fs-5">Pays</label>
                                    <select name="pays" id="pays" className="form-select h-75">
                                        <option value=""> {isloading ? "Chargement..." : "Faites un choix"} </option>
                                        {pays ?
                                            pays.map((pay) => (
                                                <option key={pay.id} value={pay.id}>{pay.pays}</option>
                                            )) : <option value="">Aucun pays enregistré</option>
                                        }
                                    </select>
                                    {errors.pays && <span className="m-1 text-danger p-2"> {errors.pays}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ville" className="fs-5">Ville</label>
                                    <input type="text" className="form-control h-100" name="ville" id="pays" />
                                    {errors.ville && <span className="m-1 text-danger p-2"> {errors.ville}</span>}
                                </div>
                                {isLoading && <span className="position-absolute"> <Loading /></span>}
                            </div>
                            <div className="modal-footer">
                                <a data-bs-dismiss="modal" ref={ref} className="btn btn-danger ">Annuler</a>
                                <button type="submit" className="btn btn-primary ">Enegistrer</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Ville;