import { useState } from "react";
import { Loading } from "../components/const";
import ModalRemboursement from "./ModalRemboursement";

const FacturesByFournisseur = ({ data, isloading }) => {

    const [selectedFacture, setFacture] = useState(null);

    return (
        <>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-xl ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Factures impayées</h1>
                            <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            {isloading ? <Loading /> :
                                <div className="row p-2 table-responsive">

                                    <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                <th>NUM-FACTURE</th>
                                                <th>MONTANT FACT</th>
                                                <th>MONTANT PAYE</th>
                                                <th>RESTE A PAYER</th>
                                                <th>ACTIONS</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                data?.map((fact, index) => (
                                                    <tr key={index + 1}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>{fact.numero}</td>
                                                        <td>{fact.montant}</td>
                                                        <td>{`${fact.paye.toLocaleString()}`}</td>
                                                        <td>{`${fact.reste.toLocaleString()}`}</td>
                                                        <td>
                                                            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalRemboursement" onClick={() => {
                                                                setFacture(fact)
                                                            }
                                                            }>REMBOURSER</button>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                </div>
                            }
                        </div>

                    </div>
                </div>

            </div>

            <ModalRemboursement facture={selectedFacture} table='conteneur' />
        </>


    );
}

export default FacturesByFournisseur;