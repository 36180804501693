import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Loading } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";




const ListeProduit = ({ titre }) => {


    const { data: produits, isloading } = UseFetch('produits/liste')
    let selectedProds = localStorage.getItem('selectedProducts') ? JSON.parse(localStorage.getItem('selectedProducts')) : null;
    let [prods, setProds] = useState(selectedProds ? selectedProds : []);



    const isSelected = (id) => {
        return prods.find(p => p.id === id && p.qte > 0) ?? false
    }
    const handleSelectProduct = (e, prod) => {
        if (!(prods.find(p => p.id === prod.id))) {
            setProds([
                ...prods,
                {
                    'id': prod.id,
                    'qte': 1,
                    'prix': 0,
                    'prod': prod.produit,
                }
            ]);
        }

    }

    const handleUnSelectProduct = (e, prod) => {
        e.preventDefault()
        if (prods.find(p => p.id === prod.id)) {
            setProds(prods.filter(elm => elm.id !== prod.id))
        }
    }

    useEffect(() => {
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
    }, [prods])
    return (

        <div className="Prouits">
            {
                isloading ? <Loading /> : <div className="ListeProduits">
            
                    <div className="row justify-content-end">
                        <div className="col-lg-4">  <SearchField plage='plage' conteneur='cont' critere='critere' /></div>
                    </div>
                    {
                        produits ? 
                            <div className="row mt-2 p-2">
                                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 plage">
                                    {
                                        produits.map((prod) => (
                                            <div className="col p-2 cont" key={prod.id} >
                                                <div className="card h-100  rounded-5 p-3 card-magasin pointer" >
                                                    <h5 className="text-center critere">{prod.produit}</h5>
                                                    <div className="row justify-content-between align-items-center">
                                                        <div className="col-lg-4">
                                                            {isSelected(prod.id) && <div className="row align-items-center">
                                                                <i className="bx bx-message-square-check fs-4 text-success" ></i>

                                                            </div>}
                                                        </div>
                                                        <div className="col-lg-6 text-center">
                                                            {!isSelected(prod.id) && <button className="btn btn-primary m-1" onClick={(e) => handleSelectProduct(e, prod)} >Choisir</button>}
                                                            {isSelected(prod.id) && <button className="btn btn-danger m-1" onClick={(e) => handleUnSelectProduct(e, prod)} >Retirer</button>}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            : <div className="row p-3 m-2 bg-danger-subtle">
                                <h5 className="text-center">Aucun produit n'a ete enregister</h5>
                            </div>
                    }
                </div>
            }


        </div>
    );

}

export default ListeProduit;