import { useState, useEffect } from "react";
import { defaulUrl, Loading, decryptData, deleteLocalStorage } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ModalAddFournisseur from "../Fournisseurs/ModalAddFournisseur";

const Addconteneur = () => {
    const { data: produits, isloading } = UseFetch('produits/liste')
    const { data: fournisseurs, isloading: loader } = UseFetch(`fournisseurs/liste`)

    const navigate = useNavigate()
    // INFO FOUNISSEURS
    let [fournisseur, setFournisseur] = useState(null);
    const [load, setLoad] = useState(false)
    // POUR CONTENEUR(CHOIX DES PRODUITS)
    let selectedProds = localStorage.getItem('selectedProducts') ? JSON.parse(localStorage.getItem('selectedProducts')) : null;
    let [prods, setProds] = useState(selectedProds ? selectedProds : []);
    const isSelected = (id) => {
        return prods?.find(p => p.id === id && p.qte > 0) ?? false
    }
    const handleSelectProduct = (e, prod) => {
        if (!(prods.find(p => p.id === prod.id))) {
            setProds([
                ...prods,
                {
                    'id': prod.id,
                    'qte': 1,
                    'prix': 0,
                    'prod': prod.produit,
                }
            ]);
        }
        handleCalulePrixTotal()
    }
    // POUR LA DEFINITION DES PRIX ET QUANTITE
    const [totalPrix, setTotalPrix] = useState(0);

    let [conteneur, setConteneur] = useState('');
    let [devise, setDevise] = useState('');
    let [depense, setDepense] = useState(0);
    let [paye, setPaye] = useState(0);
    let [valeurjour, setValeurJour] = useState(1);
    const [montantTotal, setMontantTotal] = useState(0)

    // informations du conteneur
    let MyInfo = {
        'lib_conteneur': conteneur,
        'devise': devise,
        'prix_total': totalPrix,
        'valeur_du_jour': +valeurjour,
        'depense': + depense,
        'montant_total': (+valeurjour * totalPrix) + (+depense),
        'montant_paye': + paye
    }


    const handleQte = (e, prod) => {
        prod.qte = e.target.value
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
        handleCalulePrixTotal()
    }
    const handlePrix = (e, prod) => {
        prod.prix = e.target.value
        localStorage.setItem('selectedProducts', JSON.stringify(prods))
        handleCalulePrixTotal()
    }
    const handleCalulePrixTotal = () => {
        setTotalPrix(prods?.reduce(
            (previousValue, currentValue) => { return previousValue + (+currentValue.qte * (+currentValue.prix)) },
            0));
    }
    const handleUnSelectProduct = (e, prod) => {
        e.preventDefault()
        if (prods.find(p => p.id === prod.id)) {
            setProds(prods.filter(elm => elm.id !== prod.id))
        }
        handleCalulePrixTotal()
    }
    const handleSave = () => {
        // Control de la saisie des information du fournisseurs
        if (!fournisseur) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez selectionner le fournisseur",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (!devise) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez selectionner la devise des prix dans la section information du conteneur",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (!conteneur) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez saisir le libelé du conteneur dans la section information du conteneur",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        if (prods.length === 0) {
            Swal.fire({
                title: "Aucun produit selectionné!",
                text: "Veillez selectionner au moins un produit!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }
        let isvalide = -1;
        prods.forEach(p => {
            if (+p.qte < 1 || +p.prix < 1) {
                isvalide = +prods.indexOf(p) + 1;
            }
        });
        if (isvalide >= 0) {
            Swal.fire({
                title: "Erreur de saisie!",
                text: "Vériez le prix et la quantié sur la ligne " + isvalide,
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return
        }

        if (!valeurjour) {
            Swal.fire({
                title: "Saisie obligatoire!",
                text: "Veillez saisir la valeur du jour des produits en GNF!",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            });
            return

        }

        if (+paye > (+valeurjour * totalPrix)) {
            Swal.fire({
                title: "Montant trop eleve!",
                text: "Le montant que vous avez saisi dans le champ ' Montant Payé ' dépasse ce que vous devez payer",
                icon: "error",
                toast: true,
                position: "center",
                color: 'white',
                iconColor: 'white',
                background: 'red'
            })
            return
        }


        let status = 0;
        setLoad(true)
        fetch(`${defaulUrl}conteneurs/ajout`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "fournisseur": fournisseur,
                "produits": prods,
                "conteneur": MyInfo
            })
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            setLoad(false)
            if (status === 200) {

                Swal.fire({
                    title: "Succès",
                    text: data.message,
                    icon: "success"
                })
                deleteLocalStorage()
                navigate(`/conteneurs`)
            } else {
                Swal.fire({
                    title: "Erreur",
                    text: data.message,
                    icon: "error"
                })
            }
        })

        localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
    }
    useEffect(() => {
        localStorage.setItem('selectedProducts', JSON.stringify(prods));
        handleCalulePrixTotal()
        setMontantTotal((totalPrix * valeurjour) + depense)
    }, [prods, depense])




    return (
        <div className="add-conteneur">
            {load &&
                <div className="row p-3 justify-content-center">
                    <Loading />
                </div>
            }
            <div className="container py-2 b">
                <h2 className="font-weight-light text-center text-muted py-3">CREATION DE CONTENEUR</h2>
                <div className="row ">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col-lg-6 py-1 ">
                        <h4 className="text-secondary">Fournisseur</h4>
                        <div className="input-group">
                            {loader ? <Select /> :
                                <Select className="w-75"
                                    defaultInputValue={fournisseur?.label}
                                    options={
                                        fournisseurs?.map((fourn) => (
                                            {
                                                value: `${fourn.id}`, label: `${fourn.prenoms} ${fourn.nom}(${fourn.telephone})`
                                            }
                                        ))
                                    }
                                    onChange={setFournisseur}
                                />
                            }
                            <a className="input-group-text bg-transparent btn btn-primary text-dark" data-bs-toggle='modal' data-bs-target='#modalAddFournisseur'>
                                <i className='bx bx-plus-circle'></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row" >
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card border-primary shadow radius-15">
                            <div className="card-body" >
                                <h4 className="card-title text-muted">Informations du conteneur</h4>
                                <div className="row ">
                                    <div className="form-group col-lg-4 p-2">
                                        <h5 className="card-title text-primary">Libelé du Conteneur</h5>
                                        <input type="text" className="form-control" name="conteneur" id="conteneur"
                                            defaultValue={conteneur}
                                            onChange={(e) => {
                                                setConteneur(e.target.value);
                                                localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                            }} />
                                    </div>
                                    <div className="form-group col-lg-4 p-2">
                                        <h5 className="card-title text-primary">Devise</h5>
                                        <select name="devise" id="deise" className="form-select" defaultValue={devise} onChange={(e) => {
                                            setDevise(e.target.value)
                                            localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                        }}>
                                            <option value="">Choisir une devise</option>
                                            <option value="GNF">GNF</option>
                                            <option value="CFA">CFA</option>
                                            <option value="$">$</option>
                                            <option value="Euro">Euro</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-4 p-2">
                                        <h5 className="card-title text-primary">Valeur du jour</h5>
                                        <input type="number" className="form-control hide-arrow" defaultValue={valeurjour} onChange={(e) => {
                                            setValeurJour(+e.target.value)
                                            localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                        }} min={0} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card radius-15">
                            <div className="card-body">
                                <h4 className="card-title text-muted">Choix des produits</h4>
                                <div className="row p-2">

                                    <div className="Prouits">
                                        {
                                            isloading ? <Loading /> : <div className="ListeProduits">

                                                <div className="row justify-content-end">
                                                    <div className="col-lg-4">  <SearchField plage='plage' conteneur='cont' critere='critere' /></div>
                                                </div>
                                                {
                                                    produits ?
                                                        <div className="row mt-2 p-2">
                                                            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 plage">
                                                                {
                                                                    produits.map((prod) => (
                                                                        <div className="col p-2 cont" key={prod.id} >
                                                                            <div className="card h-100  rounded-5 p-3 card-magasin pointer" >
                                                                                <h5 className="text-center critere">{prod.produit}</h5>
                                                                                <div className="row justify-content-between align-items-center">
                                                                                    <div className="col-lg-4">
                                                                                        {isSelected(prod.id) && <div className="row align-items-center">
                                                                                            <i className="bx bx-message-square-check fs-4 text-success" ></i>

                                                                                        </div>}
                                                                                    </div>
                                                                                    <div className="col-lg-6 text-center">
                                                                                        {!isSelected(prod.id) && <button className="btn btn-primary m-1" onClick={(e) => handleSelectProduct(e, prod)} >Choisir</button>}
                                                                                        {isSelected(prod.id) && <button className="btn btn-danger m-1" onClick={(e) => handleUnSelectProduct(e, prod)} >Retirer</button>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>

                                                        : <div className="row p-3 m-2 bg-danger-subtle">
                                                            <h5 className="text-center">Aucun produit n'a ete enregister</h5>
                                                        </div>
                                                }
                                            </div>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card radius-15">
                            <div className="card-body">
                                <h4 className="card-title">Saisie des Quantiés & Prix</h4>
                                <div className="row p-2">
                                    <div className="table-responsive p-2 mt-2">
                                        <table className="table table-hover table-striped table-secondary  table-bordered">
                                            <thead className="table-dark">
                                                <tr>
                                                    <td hidden>ID</td>
                                                    <td >N°</td>
                                                    <td>Designation Produit</td>
                                                    <td className="text-end" >Qte</td>
                                                    <td >PAU</td>
                                                    <td className="text-end">Action</td>
                                                </tr>
                                            </thead>
                                            <tbody id="selectedProducts">
                                                {
                                                    prods.length ? prods.map((pro, i) => (
                                                        <tr key={pro.id}>
                                                            <td hidden className="id"> {pro.id}</td>
                                                            <td > {i + 1}</td>
                                                            <td className="prod"> {pro.prod}</td>
                                                            <td className=" text-end qte">
                                                                <input type="number" min={1} className="form-control" defaultValue={+pro.qte} onChange={(e) => handleQte(e, pro)} />

                                                            </td>
                                                            <td >
                                                                <input type="number" className="form-control prix hide-arrow" defaultValue={pro.prix} onChange={(e) => handlePrix(e, pro)} min={0} />
                                                            </td>
                                                            <td className="text-end">

                                                                <button onClick={(e) => handleUnSelectProduct(e, pro)} className="btn btn-danger m-1" >Retirer</button>
                                                            </td>
                                                        </tr>
                                                    )) : <tr className="bg-danger"><td colSpan={5} className="p-2 bg-danger text-center text-danger">Vous n'avez pas choisi un produit!</td></tr>
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="row p-2 gap-1 align-items-center">
                                        <div className="form-group col-lg-4">
                                            <span className="col-lg-2 w-auto ">Montant Total en devise</span>
                                            <input type="text" disabled className="form-control"
                                                value={`${totalPrix.toLocaleString()} ${devise}`} />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <span className="col-lg-2 w-auto ">Montant Total en GNF</span>
                                            <input type="text" disabled className="form-control"
                                                value={`${(totalPrix * valeurjour).toLocaleString()} GNF`} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card radius-15">
                            <div className="card-body">
                                <h4 className="card-title">Autres informations</h4>
                                <div className="row ">
                                    <div className="form-group col-lg-4 p-2">
                                        <h5 className="card-title text-primary">Dépenses totale</h5>
                                        <input type="number" className="form-control hide-arrow" defaultValue={depense} onChange={(e) => {
                                            setDepense(+e.target.value)
                                            localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                        }} min={0} />
                                    </div>
                                    <div className="form-group col-lg-4 p-2">
                                        <h5 className="card-title text-primary">Montant Total</h5>
                                        <div className="p-2 bg-secondary-subtle rounded">{(totalPrix * valeurjour + depense).toLocaleString()} GNF</div>

                                    </div>
                                    <div className="form-group col-lg-4 p-2">
                                        <h5 className="card-title text-primary">Montant Payé</h5>
                                        <input type="number" className="form-control hide-arrow" defaultValue={paye} onChange={(e) => {
                                            setPaye(e.target.value)
                                            localStorage.setItem('storedInfo', JSON.stringify(MyInfo));
                                        }} min={0} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto text-center flex-column d-none d-sm-flex">
                        <div className="row h-50">
                            <div className="col border-end">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                        <h5 className="m-2">
                            <span className="badge rounded-pill bg-light border">&nbsp;</span>
                        </h5>
                        <div className="row h-50">
                            <div className="col">&nbsp;</div>
                            <div className="col">&nbsp;</div>
                        </div>
                    </div>
                    <div className="col py-2">
                        <div className="card radius-15">
                            <div className="card-body">
                                <h4 className="card-title">Validation</h4>
                                <div className="modal-footer">
                                    <button className="btn btn-danger m-1" onClick={() => deleteLocalStorage()}>Annuler</button>
                                    <button className="btn btn-success m-1" onClick={() => handleSave()}>Enregistrer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalAddFournisseur />
        </div>
    );
}

export default Addconteneur;