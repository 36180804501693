const Aide = () => {
    return (
        <div className="Aide">
            <div className="row">
                <div className="col-lg-4">
                    <div className="video">
                        <video src='../videos/capture.mp4' controls width='70%'/>


                    </div>
                    <div className="titre h6">
                        Titre de la video
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Aide;