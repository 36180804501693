import { Loading } from "../components/const";
import SearchField from "../components/SeachField";
import UseFetch from "../components/UseFetch";

const Stocks = () => {
    const { data: produits, isloading } = UseFetch('stocks/actuel');
    return (
        <div className="Stocks">
            <div className="row my-2">
                <div className="col-lg-8 h3 text-primary">
                    STOCK ACTUEL
                </div>
            </div>
            <div className="row ">
                <div className="row justify-content-end m-0">
                    <div className="col-lg-4">
                        <SearchField />
                    </div>
                </div>
            </div>
            {
                isloading ? <Loading /> : produits ? <div className="table-responsive p-2">
                    <table className=" table table-striped table-hover">
                        <thead className="table-primary">
                            <tr>
                                <th>N°</th>
                                <th>PRODUITS</th>
                                <th>PAU</th>
                                <th>QUANTITE</th>
                            </tr>

                        </thead>
                        <tbody>
                            {
                                produits.map((prod, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{prod.produit}</td>
                                        <td>{prod.prix}</td>
                                        <td>{prod.quantite}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div> : <div className="row justify-content-center bg-danger-subtle p-2 fs-5">
                    Le stock est vide pour le moment!
                </div>
            }

        </div>
    );
}

export default Stocks;