import { useRef } from "react";
import { decryptData, defaulUrl ,Loading} from "../components/const";
import { useState } from "react";
import Swal from "sweetalert2";
const ModalAddFournisseur = () => {

    const ref = useRef()

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleSubmite = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const formdata = new FormData(e.target);
        let status = 0;
        fetch(`${defaulUrl}fournisseurs/add`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formdata
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {

                    ref.current.click();
                })
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);


    }
    return (
        <div className="modal fade " id="modalAddFournisseur" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Ajouter un fournisseur</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form className="p-3" onSubmit={(e) => handleSubmite(e)}>
                    {isLoading && <Loading />}
                        <div className=" form-group my-2">
                            <label htmlFor="nom" className="fs-5">Nom Fournisseur <span className="text-danger">(*)</span> </label>
                            <input type="text" className="form-control " name="nom" />
                            {errors?.nom && <span className="text-danger mt-2">{errors?.nom}</span>}
                        </div>
                        <div className=" form-group my-2">
                            <label htmlFor="prenoms" className="fs-5">Prenom(s) Fournisseur <span className="text-danger">(*)</span> </label>
                            <input type="text" className="form-control " name="prenoms" />
                            {errors?.prenoms && <span className="text-danger mt-2">{errors?.prenoms}</span>}
                        </div>
                        <div className=" form-group my-2">
                            <label htmlFor="telephone" className="fs-5">Tel Fournisseur <span className="text-danger">(*)</span> </label>
                            <input type="number" className="form-control  hide-arrow" name="telephone" />
                            {errors?.telephone && <span className="text-danger mt-2">{errors?.telephone}</span>}
                        </div>
                        <div className=" form-group my-2">
                            <label htmlFor="adresse" className="fs-5">Adresse Fournisseur <span className="text-danger">(*)</span> </label>
                            <input type="text" className="form-control " name="adresse" />
                            {errors?.adresse && <span className="text-danger mt-2">{errors?.adresse}</span>}
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</a>
                            <button type="submite" className="btn btn-success">Enregistrer</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalAddFournisseur;