import { useRef, useState } from "react";
import { decryptData, defaulUrl, Loading } from "../components/const";
import Swal from "sweetalert2";

const ModalRemboursement = ({ facture, table }) => {
    const [paye, setPaye] = useState(0)
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [source, setSource] = useState(null)
    const ref = useRef();
    const handlePayer = () => {

        if (paye < 1000 || paye === null) {
            setErrors({ 'montant': 'Entrez une valeure superieure ou egale a 1 000 GNF!' });
            return
        }
        if (paye > (+facture?.reste)) {
            setErrors({ 'montant': 'Vous avez saisi un montant trop élévé!' });
            return
        }
        // if (table === 'factures' && !source) {
        //     setErrors({ 'source': 'Choisissez la source du montant!' });
        //     return
        // }
        // if (table === 'factures' && paye > facture.solde && +source === 1) {
        //     setErrors({ 'montant': 'Le montant saisi est supérieur au solde du client!' });
        //     return
        // }
        let status = 0;
        setIsLoading(true)
        const formData = new FormData();
        formData.set('numero', facture?.numero)
        formData.set('table', table)
        formData.set('montant', +paye)
        // formData.set('source', source)

        fetch(`${defaulUrl}payer/${facture?.numero}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                ContentType: 'application/json',
                Authorization: `Bearer ${decryptData('ACCESS_TOKEN')}`
            },

            body: formData
        }).then((response) => {
            status = response.status;
            return response.json()
        }).then(data => {
            setIsLoading(false);
            console.log(data)
            if (status == 200) {
                Swal.fire({
                    title: 'Succès',
                    text: data.message,
                    icon: 'success'
                }).then(() => {
                    setPaye(0);
                    ref.current.click();
                })
                setErrors(null)
            } else {
                setErrors(data.errors);
            }
        })
        setErrors(null);
    }
    return (
        <div className="modal fade " id="modalRemboursement" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary-subtle">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Regler la facture # {facture?.numero}</h1>
                        <button type="button " className="btn-close btn-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <span className="text-primary bg-secondary-subtle px-3">{facture?.client}({facture?.telephone})</span>
                    <form >
                        <div className="modal-body position-relative">
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant à payer</label>
                                <div className="form-control hide-arrow p-2 bg-secondary-subtle">{facture?.reste?.toLocaleString()} GNF</div><div />
                            </div>
                            <div className="form-group">
                                <label htmlFor="" className="fs-5">Montant payé</label>
                                <input type="number" className="form-control hide-arrow" defaultValue={paye} onChange={(e) => setPaye(+e.target.value)} />
                                {errors?.montant && <span className="text-danger p-1">{errors?.montant}</span>
                                }
                            </div>
                            {/* {table === 'factures' && <div className="form-group">
                                <label htmlFor="" className="fs-5">Source</label>
                                <select name="source" id="source" className="form-select" onChange={(e) => setSource(e.target.value)}>
                                    <option value={null}>Faites un choix</option>
                                    <option value="0">Poche du client</option>
                                    <option value="1">Compte du client</option>
                                </select>
                                {errors?.source && <span className="text-danger p-1">{errors?.source}</span>
                                }
                            </div>} */}
                            {isLoading && <span className="position-absolute"> <Loading /></span>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" ref={ref}>Annuler</button>
                            <button type="button" className="btn btn-success" onClick={() => handlePayer()} >Payer</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ModalRemboursement;