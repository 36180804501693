
import { NavLink } from "react-router-dom";
import { defaulUrl, Loading, decryptData } from "../components/const";
import { useState } from "react";
import Swal from "sweetalert2";
import UseFetch from "../components/UseFetch";
import AddUser from "./AddUser";
const Users = () => {
    const { data: users, isloading } = UseFetch('users');
    let [isLoading, setIsLoading] = useState(false);
    let status = 0;
    const handleDelete = (e, id) => {
        e.preventDefault();
        Swal.fire({
            title: "Suppression",
            text: "Vous allez supprimer définitivement ce compte?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OUI",
            cancelButtonText: "NON",
            confirmButtonColor: "red",
            cancelButtonColor: "blue"
        }).then((resp) => {
            if (resp.isConfirmed) {
                setIsLoading(true);
                fetch(`${defaulUrl}users/delete/${id}`, {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + decryptData('ACCESS_TOKEN'),
                        Accept: 'application/json'
                    }
                }).then((response) => {
                    status = response.status;
                    return response.json();
                }).then((data) => {
                    setIsLoading(false)
                    if (status === 200) {
                        Swal.fire({
                            title: "Suppression de compte",
                            text: data.message,
                            icon: "success"
                        })
                    }
                })
            }
        })
    }
    const handeEnablbe = (id, e) => {
        let status = 0;
        e.preventDefault()
        fetch(`${defaulUrl}users/enable/${id}`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + decryptData('ACCESS_TOKEN'),
                Accept: 'application/json'
            }
        }).then((response) => {
            status = response.status;
            return response.json();
        }).then(data => {
            if (status === 200) {
                // setUsers(data.users)
            }

        })
    }
    console.log(users)
    return (isloading ? <Loading /> :
        <div className="Users contanier-fluid">
            <div className="row justify-content-between p-2 mb-2">
                <div className="col-lg-8 ">
                    <h2>Gestion des utilisateurs</h2>
                </div>
                <div className="col-lg-3 text-end">
                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalUser">Nouveau utilisateur</button>
                </div>
            </div>

            <div className="row p-2 my-2">
                <div className="row justify-content-end m-0">
                    <div className="col-lg-4 py-2">  <input type="search" className="form-control" /></div>
                </div>
                <div className="row">
                    <div className="col table-responsive">
                        {isLoading && <Loading />}
                        <table className="table table-white shadow table-bordered table-hover table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th hidden>#ID</th>
                                    <th>N°</th>
                                    <th>PRENOMS ET NOM</th>
                                    <th>EMAIL</th>
                                    <th>TELEPHONE</th>
                                    <th>DROIT</th>
                                    <th>MAGASIN</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users ? users.map((user, index) => (
                                    <tr key={user.id}>
                                        <td hidden>{user.id}</td>
                                        <td>{index + 1}</td>
                                        <td>{user.firstname} {user.lastname}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.droit}</td>
                                        <td>{user.magasin}</td>
                                        <td className="text-end">
                                            <button hidden className="btn btn-info m-1">detail</button>
                                            <button className="btn btn-danger m-1" onClick={(e) => handleDelete(e, user.id)}>Supp.</button>

                                        </td>
                                    </tr>
                                ))
                                    : <tr >
                                        <td colSpan="9" className="p-3 text-center bg-danger-subtle text-primary w-100">
                                            Aucun utilisateur trouve
                                        </td>
                                    </tr>
                                }
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>





            {/* MODALE ADD USER */}


            <AddUser />
        </div>

    );
}

export default Users;